import flatten, { unflatten } from 'flat'
import { difference, isArray, isEmpty, isNil, omit } from 'lodash'
import { nanoid } from 'nanoid'
import { APPLY_JOB_MUTATION, GET_JOB_DETAIL_QUERY, GET_MASTER_DATA, apolloClient } from '~/common/apollo'
import { CREATE_SAVE_MUTATION, SYNC_ANONYMOUS_WORKER_DATA, UPDATE_SAVE_MUTATION } from '~/common/apollo/mutations/jobs-filter.mutation'
import { SAVE_FILTER_QUERY } from '~/common/apollo/queries/job-filter.query'
import { EVENTS, PATHS } from '~/common/constants'
import { COMMON_EVENT_CLICK_TRACKING } from '~/common/constants/tracking.constant'
import { convertStateToArray, convertStateToString, getSaveSearchAnoName, mappingFilterJobSearch, removeNullOrUndefined } from '~/common/helpers/mapping.helper'
import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import { CORE_BEHAVIOR_TRACKING, EVENT_DEFAULT_ID, TYPE_JOB } from '~/common/tracking/event-click.constant'
import { logClickEvent, logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { jobAlertsStore } from '~/components/job-alert-drawer/job-alert-drawer.store'
import { authStore, masterStore, notifyStore, routerStore } from '~/stores'
import { GET_LIST_JOBS_QUERY } from './care-find-job.graphql'
@store()
class CareFindJobStore {
	@observable searchData = null
	@observable sortKey = 'SCORE'

	@observable token = null
	@observable searchInfo
	@observable jobs = []
	@observable suggestedJobs = []
	@observable searchParams = {}
	@observable jobApplicantId = ''
	@observable shifts = {}
	@observable activeFilter = {}
	@observable filterOptions = null
	@observable listSaveSearch = []
	@observable listSaveSearchView = []
	@observable isShowSaveSearchDialog = false
	@observable searchString
	@observable totalCount
	@observable pageInfo = {}
	@observable payInfoList = []
	@observable lastSearch = {}
	@observable isActiveFilter = false
	@observable redirectSignUpLastJobId = null
	@observable filterValues = {}
	@observable savedFilterId = ''
	@observable activeFilterId = ''
	@observable searchSessionId = nanoid()
	@observable isAutoPopulate = false
	@observable valueSearch = {}
	@observable valueItem = {}

	@computed
	get searchStr() {
		return this.searchString
	}

	@computed
	get disciplineVal() {
		return []
	}

	@computed
	get specialtyOptionsVal() {
		return null
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@computed
	get hideDistance() {
		return !this.filterValues.addressCities
	}

	@computed
	get professionOptions() {
		return masterStore.disciplines
	}

	@computed
	get suggestedJobsItems() {
		return this.suggestedJobs
	}

	@computed
	get jobShiftOptions() {
		return masterStore.jobShifts
	}

	@computed
	get facilityTypeSettings() {
		return masterStore.facilityTypesSettings
	}

	@computed
	get specialtyOptions() {
		return masterStore.jobDisciplines
	}

	@computed
	get isSaveSearch() {
		return this.listSaveSearch?.every((item) => item?.isActive === false)
	}

	@computed
	get getActiveItem() {
		const activeValue = authStore.id
			? this.listSaveSearch?.find((item) => item.isActive === true)?.filterCondition
			: this.listSaveSearch?.find((item) => item.isActive === true)?.filterConditions

		const activeItem = convertStateToArray(activeValue)
		const anoActiveValue = this.listSaveSearch?.find((item) => item.isActive === true)?.filterConditions
		const anoActiveItem = convertStateToArray(anoActiveValue)
		const mapData = authStore.id ? activeItem : anoActiveItem
		return this.transferDataParams({ ...mapData, isActive: true })
	}

	@computed
	get getActiveItemSaveFilter() {
		return !isEmpty(this.listSaveSearch) && this.listSaveSearch?.find((item) => item.isActive === true)
	}

	@action
	setPayInfoList = (values) => {
		this.payInfoList = values
	}

	@action
	setActiveFilterId = async (id) => {
		this.activeFilterId = id
	}
	@action
	setSavedFilterId = (id) => {
		this.savedFilterId = id
	}
	@action
	setValueItem = async (value) => {
		this.valueItem = value
	}

	@action
	setFilterValues = (values) => {
		this.filterValues = values
	}

	@action
	resetFilterValue = () => {
		this.filterValues = {}
	}

	@action
	resetSearchData = () => {
		this.searchData = null
	}

	@action
	setSearchStr = (searchInput) => {
		this.searchString = searchInput
	}

	@action
	setRedirectSignUpLastJobId = (job) => {
		this.redirectSignUpLastJobId = job
	}

	@action
	setInitSearchListList = () => {
		this.listSaveSearch = []
	}

	@action
	disciplineOptions(nameProfession) {
		let compresData = !!nameProfession
			? Object.values(masterStore?.jobDisciplines?.[nameProfession] || {})
			: Object.assign({}, ...Object.values(masterStore?.jobDisciplines || {}))

		return Object.values(compresData).map((key, value) => ({
			label: key?.long_name,
			value: key.skill_name,
			id: key?.id,
		}))
	}

	@action
	transferDataParams = (data) => {
		return {
			disciplines: data?.disciplines,
			exclusiveJob: data?.exclusiveJob,
			maxWage: data?.maxWage,
			payAmountMin: data?.payAmountMin,
			minMatchingPercentage: data?.minMatchingPercentage,
			nearBy: data?.nearBy,
			shifts: data?.shifts,
			specialtyIds: data?.specialties,
			radius: data?.radius,
			addressCities: data?.addressCities,
			addressStates: data?.addressStates,
			isActive: data?.isActive,
			workTypes: data?.workTypes,
		}
	}

	@action
	setSearchParams = (newParams) => {
		this.searchParams = { ...this.searchParams, ...newParams }
	}

	@action
	resetSearchFilter = () => {
		this.listSaveSearch = this.listSaveSearch?.map((item) => ({ ...item, isActive: false }))
		this.filterValues = {}
		if (!authStore.id) {
			window.localStorage.setItem('saveSearchAno', JSON.stringify(this.listSaveSearch))
		}
	}

	@action
	fetchJobShift = async () => {
		const response = await apolloClient.query({
			query: GET_MASTER_DATA,
		})
		this.shifts =
			!isNil(response.data?.appInit?.masterData?.job?.shift) &&
			Object.values(response.data?.appInit?.masterData?.job?.shift).map((item) => {
				return {
					label: item,
					value: item,
				}
			})
	}

	@action
	setActiveFilterJob = (value) => {
		const transferData = {
			...value,
		}

		const activeSearchValue = transferData

		this.activeFilter = activeSearchValue
	}

	@action
	setIsActiveClick = (value) => {
		this.isActiveFilter = value
	}

	@action
	getFlattenFilter = (value) => {
		return omit(value, ['workerId', 'companyId', 'id', 'radius', 'isActive'])
	}

	@action
	setValueSearch = async (value) => {
		return (this.valueSearch = removeNullOrUndefined(value))
	}

	@action
	onSubmitSearch = async (searchData, history) => {
		this.resetFilterValue()

		const previousSearchData = this.searchData && flatten(this.searchData)
		const newSearchData = flatten(searchData)

		const diff = difference(previousSearchData, newSearchData)

		if (!this.searchData || !isEmpty(diff)) {
			this.token = nanoid()
		}

		this.searchData = searchData

		this.setIsActiveClick(false)

		const transferData = mappingFilterJobSearch(this.searchData, false)

		const convertStringJob = convertStateToString(transferData)
		this.setFilterValues(convertStringJob)

		history.push(PATHS.care.search)
	}

	@action
	refreshSearchJob = async (activeFilter, keyword) => {
		this.searchInfo = null
		this.jobs = []
	}

	@action
	setIsAutoPopulate = async (value) => {
		return (this.isAutoPopulate = value)
	}

	@action
	onCloseSearch = () => {
		this.searchData = null
		this.sortKey = authStore.id ? 'SCORE' : 'POSTED_DATE'
	}

	@action
	fetchMoreJobs = async (filterCondtion) => {
		const sortBy = this.sortKey && {
			field: this.sortKey,
			order: this.sortKey === 'DISTANCE' ? 'asc' : 'desc',
		}
		const { city, ...restFilter } = filterCondtion
		const disciplines = restFilter?.disciplines
		const skillNameLength = restFilter?.specialties?.length

		const restValue =
			!!disciplines && skillNameLength === 0
				? {
						...restFilter,
						specialtyIds: this.disciplineOptions(disciplines).map((item) => item?.value),
				  }
				: restFilter

		const flatternSaveSearch = omit(mappingFilterJobSearch(restValue, ['name', 'isActive', 'id', 'specialty', 'specialties']))

		const response = await apolloClient.query({
			query: GET_LIST_JOBS_QUERY,
			variables: {
				filter: { ...flatternSaveSearch },
				first: 10,
				sortBy,
				after: this.endCursor,
				...(!isEmpty(this.savedFilterId) && { savedFilterId: this.savedFilterId }),
			},
			context: {
				clientName: authStore.id ? null : 'public',
			},
		})

		this.jobs = [...this.jobs, ...response?.data?.searchJobs?.nodes]
		this.pageInfo = response?.data?.searchJobs?.pageInfo
		logClickEvent(COMMON_EVENT_CLICK_TRACKING.viewMoreBtn, {
			job_ids: [...this.jobs, ...response?.data?.searchJobs?.nodes].map((item) => item?.id),
		})
		if (!isEmpty(this.savedFilterId)) {
			this.setSavedFilterId('')
		}

		logDefaultActionEvent(EVENT_DEFAULT_ID.loadMoreLanding, {
			jobs: this.jobs?.map((x, index) => {
				return {
					id: x.id,
					min_wage: x?.payAmountMin,
					max_wage: x?.payAmountMax,
					order: index,
					percent_matched: x.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / 20) + 1,
			limit: 20,
			offset: this.jobs?.length || 0,
			list_type: TYPE_JOB.searchResults,
		})
	}

	@action
	onUpdateSortKey = async (sortKey, filterValues, searchStr) => {
		if (sortKey !== this.sortKey) {
			this.sortKey = sortKey
			this.searchInfo = { totalCount: this.totalCount || 0 }

			await this.fetchSearchResults({ ...filterValues }, searchStr)
		}
	}

	@action
	onApplyJob = async (jobId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.jobs = this.jobs?.map((job) => (job.id === jobId ? { ...job, jobApplicant } : job))
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleFilterData(dataFilter) {
		const { jobId, radius, ...values } = dataFilter

		const disciplines = dataFilter.disciplines
		const skillNameLength = dataFilter.specialties?.length
		if ((!!disciplines && skillNameLength === 0) || (!!disciplines && !skillNameLength)) {
			values.specialties = this.disciplineOptions(disciplines).map((item) => item?.value)
		}

		if (isArray(values?.locationState)) {
			values.addressCities = null
		}

		const filterRadius = omit(values, ['radius'])
		const data = flatten(filterRadius)
		// const normalizeData = omitBy(data, (value) => (typeof value === 'object' ? isEmpty(value) : !value))

		return isArray(values?.locationState)
			? unflatten({
					...data,
					locationCity: null,
			  })
			: unflatten({ ...data })
	}

	@action
	fetchSaveSearhFilter = async () => {
		if (authStore.id) {
			const response = await apolloClient.query({
				query: SAVE_FILTER_QUERY,
			})
			this.listSaveSearchView = response?.data?.savedFilters?.nodes?.map((item) => {
				const { filterCondition } = item
				const { conditions } = filterCondition || {}
				const { licenseSpecialtyOptions } = masterStore

				const specialtyIds = licenseSpecialtyOptions?.filter((item) => conditions?.specialtyIds?.includes(item?.value))

				if (item.id === this.activeFilterId) {
					return {
						...item,
						...item.filterCondition.conditions,
						specialtyIds,
						isActive: true,
					}
				} else {
					return {
						...item,
						...item.filterCondition.conditions,
						specialtyIds,
						isActive: false,
					}
				}
			})

			this.listSaveSearch = response?.data?.savedFilters?.nodes?.map((item) => {
				const { filterCondition } = item
				const { conditions } = filterCondition || {}
				const { licenseSpecialtyOptions } = masterStore

				const specialtyIds = licenseSpecialtyOptions?.filter((item) => conditions?.specialtyIds?.includes(item?.value))
				if (item.id === this.activeFilterId) {
					return {
						...item,
						...item.filterCondition.conditions,
						specialtyIds,
						isActive: true,
					}
				} else {
					return {
						...item,
						...item.filterCondition.conditions,
						specialtyIds,
						isActive: false,
					}
				}
			})
		}
	}

	@action
	handleSaveSearch = async (values) => {
		const valueFilter = values.hasOwnProperty('specialtyIds')
			? {
					...values,
					specialtyIds: values?.specialtyIds.map((item) => item.value),
			  }
			: values
		const filteredArray = Object.entries(valueFilter).filter(([key, value]) => value !== undefined)
		const filteredObject = Object.fromEntries(filteredArray)
		const nameSaved = filteredObject?.name
		const dataSave = omit(filteredObject, ['name', 'isActive'])
		const disciplines = dataSave?.disciplines
		const skillNameLength = dataSave?.specialtyIds?.length
		const restValue =
			!!disciplines && skillNameLength === 0
				? {
						...dataSave,
						specialtyIds: this.disciplineOptions(disciplines).map((item) => item?.value),
				  }
				: dataSave

		const filterCondtionsLogin = omit(mappingFilterJobSearch(restValue), ['specialties'])
		const { frequency, alertChannels, enableAlert, pauseAlert, pauseAlertPeriod, pauseAlertUntil } = restValue || {}

		const variables = {
			name: nameSaved,
			frequency: frequency,
			enableAlert: enableAlert,
			alertChannels: alertChannels,
			pauseAlert: pauseAlert,
			pauseAlertPeriod: pauseAlertPeriod,
			pauseAlertUntil: pauseAlertUntil,
			filterCondition: filterCondtionsLogin,
		}
		try {
			if (authStore.id) {
				const { data } = await apolloClient.mutate({
					mutation: CREATE_SAVE_MUTATION,
					variables: variables,
				})
				const id = data?.createSavedFilter?.id
				await this.fetchSaveSearhFilter()
				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)
				await notifyStore.success('$MESSAGES.SUCCESS_ADD_NEW_SAVE_SEARCH')
				await jobAlertsStore.setJobAlertId(id)
				await logClickEvent(COMMON_EVENT_CLICK_TRACKING.addBtn, {
					...variables,
				})
				await logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.addNewSearchAddSuccess, {
					...variables,
				})
			}
		} catch (error) {
			notifyStore.error(error.message)
			await logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.addNewSearchAddFailed, {
				...variables,
			})
		}
	}

	@action
	handleUpdateSearch = async (values) => {
		const valueFilter = values.hasOwnProperty('specialtyIds')
			? {
					...values,
					specialtyIds: values?.specialtyIds.map((item) => item.value),
			  }
			: values

		const nameSaved = valueFilter?.name
		const dataSave = omit(valueFilter, ['name'])
		const id = valueFilter?.id
		const filterConditions = omit(convertStateToString(mappingFilterJobSearch(this.handleFilterData(dataSave))), ['radius', 'isActive'])
		const { frequency, alertChannels, enableAlert, pauseAlert, pauseAlertPeriod, pauseAlertUntil } = valueFilter || {}
		const variables = {
			id: id,
			name: nameSaved,
			frequency: frequency,
			enableAlert: enableAlert,
			alertChannels: alertChannels,
			pauseAlert: pauseAlert,
			pauseAlertPeriod: pauseAlertPeriod,
			pauseAlertUntil: pauseAlertUntil,
			filterCondition: filterConditions,
		}

		if (authStore.id) {
			try {
				const { data } = await apolloClient.mutate({
					mutation: UPDATE_SAVE_MUTATION,
					variables: variables,
				})

				await this.fetchSaveSearhFilter()
				jobAlertsStore.setMode(jobAlertsStore.MODE_LIST.view)

				await notifyStore.success('$MESSAGES.SUCCESS_UPDATE_SAVE_SEARCH')
				const id = data?.updateSavedFilter?.id
				await jobAlertsStore.setJobAlertId(id)
				// await this.fetchSaveSearhFilter()
				await logClickEvent(COMMON_EVENT_CLICK_TRACKING.saveBtn, {
					...variables,
				})
				await logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.editSearchSaveSuccess, {
					...variables,
				})
			} catch (error) {
				notifyStore.error(error.message)
				await logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.editSearchSaveFailed, {
					...variables,
				})
			}
		}
	}

	@computed
	get hasNextPage() {
		return this.pageInfo?.hasNextPage || false
	}

	@computed
	get endCursor() {
		return this.pageInfo?.endCursor
	}

	@action
	setActiveItemFilter = (id, setValues) => {
		this.listSaveSearch = this.listSaveSearch?.map((item) => {
			if (item.id === id) {
				return {
					...item,
					isActive: true,
				}
			} else {
				return {
					...item,
					isActive: false,
				}
			}
		})
		this.setActiveFilterId(id)
		setValues(this.getActiveItem)
		if (!authStore.id) {
			window.localStorage.setItem('saveSearchAno', JSON.stringify(this.listSaveSearch))
		}
	}

	@action
	setIsShowSaveSearchDialog = (value) => {
		this.isShowSaveSearchDialog = value
	}

	@action
	fetchSearchResults = async (filterCondtion) => {
		const sortBy = this.sortKey && {
			field: this.sortKey,
			order: this.sortKey === 'DISTANCE' ? 'asc' : 'desc',
		}

		const { city, ...restFilter } = filterCondtion || {}
		// if (restFilter.hasOwnProperty('specialties')) {
		// 	restFilter.specialtyIds = restFilter.specialties
		// }
		// if (restFilter.hasOwnProperty('specialtyIds')) {
		// 	if (restFilter.specialtyIds.every((item) => isObject(item) === true)) {
		// 		restFilter.specialtyIds = restFilter.specialtyIds.map((item) => item.value)
		// 	}
		// }

		const flatternSaveSearch = omit(mappingFilterJobSearch(convertStateToString(restFilter)), ['name', 'isActive', 'id', 'specialty', 'specialties'])

		const response = await apolloClient.query({
			query: GET_LIST_JOBS_QUERY,
			variables: {
				filter: { ...flatternSaveSearch },
				first: 20,
				sortBy,
				...(!isEmpty(this.savedFilterId) && { savedFilterId: this.savedFilterId }),
			},
			context: {
				clientName: authStore.id ? null : 'public',
			},
		})

		this.jobs = response?.data?.searchJobs?.nodes
		this.pageInfo = response.data?.searchJobs?.pageInfo
		this.totalCount = response?.data?.searchJobs?.totalCount

		if (!isEmpty(this.savedFilterId)) {
			this.setSavedFilterId('')
		}

		logDefaultActionEvent(EVENT_DEFAULT_ID.defaultSearchResult, {
			job_ids: this.jobs?.map((x) => x.id),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / 20) + 1,
			limit: 20,
			offset: this.jobs?.length || 0,
			search_param: { filter: this.filterValues, sortBy },
			search_session_id: this.searchSessionId,
		})
		logDefaultActionEvent(EVENT_DEFAULT_ID.loadInitialLanding, {
			jobs: this.jobs?.map((x, index) => {
				return {
					id: x.id,
					min_wage: x?.payAmountMin,
					max_wage: x?.payAmountMax,
					order: index,
					percent_matched: x.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.jobs?.length / 20) + 1,
			limit: 20,
			list_type: TYPE_JOB.searchResults,
		})
	}
	@action
	fetchApplicant = async (id) => {
		const { data } = await apolloClient.query({ query: GET_JOB_DETAIL_QUERY, variables: { id } })
		this.jobApplicantId = data?.workerAssignment?.job?.jobApplicant?.id
	}

	@action
	handleSyncAnoAction = async () => {
		if (authStore.id) {
			const jobFavoriteAnoId = !isNil(window.localStorage.getItem('jobFavoriteAnoId')) ? window.localStorage.getItem('jobFavoriteAnoId') : null
			const anoSaveSearch = JSON.parse(window.localStorage.getItem('anoSaveSearch')) || null

			const handleGetNameSaveSearch = this.listSaveSearch.length > 0 ? getSaveSearchAnoName(this.listSaveSearch) : 'My saved search 1'

			const dataMapping = anoSaveSearch
				? {
						name: handleGetNameSaveSearch,
						filterCondition: mappingFilterJobSearch(anoSaveSearch),
				  }
				: null

			let favoriteJobsAttributes = []
			let savedFilters = []

			if (!isNil(jobFavoriteAnoId)) {
				favoriteJobsAttributes.push({
					jobId: jobFavoriteAnoId,
					action: 'favorite',
				})
			}
			if (!isEmpty(anoSaveSearch)) {
				savedFilters.push(dataMapping)
			}

			try {
				await apolloClient.mutate({
					mutation: SYNC_ANONYMOUS_WORKER_DATA,
					variables: {
						favoriteJobsAttributes,
						savedFilters,
					},
				})
				if (!isNil(jobFavoriteAnoId)) {
					await routerStore.goPage(`${PATHS.care.my_jobs}/favorites`)
					await window.localStorage.removeItem('jobFavoriteAnoId')
				}

				if (!isNil(anoSaveSearch)) {
					await routerStore.goPage(`${PATHS.care.search}`)

					await jobAlertsStore.setShowJobAlertDrawer(true)
					await this.fetchSaveSearhFilter()
					jobAlertsStore.setMode('view')

					await window.localStorage.removeItem('anoSaveSearch')
				}
			} catch (error) {
				await notifyStore.error(error.message)
				await window.localStorage.removeItem('jobFavoriteAnoId')
			}
		}
	}

	@event(EVENTS.authStore.logout)
	userLogout() {
		this.searchData = null
		this.sortKey = authStore.id ? 'SCORE' : 'POSTED_DATE'
	}
}

export const careFindJobStore = new CareFindJobStore()
