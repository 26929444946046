import { createMuiTheme } from '@material-ui/core'

export const candidateTheme = createMuiTheme({
	props: {
		MuiTextField: {
			variant: 'outlined',
			InputLabelProps: { shrink: true },
			InputProps: { notched: false },
		},
	},
	overrides: {
		MuiDrawer: {
			paperAnchorDockedLeft: {
				borderRight: '1px solid #F4F5F4',
			},
		},
		MuiListItem: {
			root: {
				color: '#868686',
				fontSize: '14px',
				fontWeight: 800,
				letterSpacing: '0.1px',
				'&.Mui-selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiFormHelperText: {
			root: {
				fontSize: '12px',
				fontWeight: 500,

				'&.Mui-error': {
					marginLeft: 0,
					marginRight: 0,
				},
			},
		},
		MuiSlider: {
			thumb: {
				backgroundColor: '#fff',
				boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
			},
			track: {
				backgroundColor: '#D1D1D1',
			},
			rail: {
				backgroundColor: '#64B6CF',
				opacity: 1,
			},
		},
		MuiAutocomplete: {
			option: {
				'&[aria-selected="true"]': {
					backgroundColor: 'transparent',
				},
			},
			inputRoot: {
				paddingTop: '0 !important',
				paddingBottom: '0 !important',
			},
		},
		MuiFormLabel: {
			root: {
				color: '#333335',
				whiteSpace: 'nowrap',
				fontWeight: 700,
				fontSize: '14px',
				lineHeight: '22px',
				'&$focused': {
					color: '#333335',
				},
			},
		},
		MuiFormControl: {
			root: {
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: 600,
				letterSpacing: '0.1px',

				'& label + .MuiInputBase-root': {
					marginTop: '20px',

					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent',
					},

					'&.MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent',
					},
				},
			},
		},
		MuiFormControlLabel: {
			label: {
				fontWeight: 800,
				fontSize: '12px',
				lineHeight: '18px',
				letterSpacing: '0.2px',
				color: '#00AAE5',
			},
		},
		MuiInputBase: {
			root: {
				color: '#333335',
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '24px',
				borderColor: 'red',
				borderRadius: '8px',
				'& .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor: '#E6E6E6',
				},
				'&.MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#E6E6E6',
				},

				'&:has(input:placeholder-shown)': {
					backgroundColor: '#F8F8F8',
				},
				'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderColor: '#5BD5FF',
				},
				'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#5BD5FF',
				},
				'&.Mui-disabled': {
					backgroundColor: '#F8F8F8',
				},
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: '8px',
			},
			input: {
				padding: '12px 16px !important',
			},
		},

		MuiInputLabel: {
			shrink: {
				transform: 'translate(0, -1.5px) !important',
			},
			root: {
				width: '100%',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'transparent !important',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
		},
		MuiButtonGroup: {
			groupedOutlinedPrimary: {
				'&:hover': {
					borderColor: '#99E1E1',
				},
			},
		},
		MuiButton: {
			root: {
				textTransform: 'unset',
			},
			outlinedPrimary: {
				border: '2px solid #64B6CF',
				color: '#64B6CF',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					color: '#64B6CF',
					border: '2px solid #64B6CF',
				},
			},
			outlinedSecondary: {
				border: '2px solid #F5826B',
				color: '#F5826B',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					border: '2px solid #64B6CF',
					color: '#64B6CF',
				},
			},
			containedPrimary: {
				color: '#F8F8F8',
				backgroundColor: '#64B6CF',
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: '#91DDF4',
					color: '#F8F8F8',
					boxShadow: 'none',
				},
			},
			containedSizeLarge: {
				padding: '8px',
			},
			containedSecondary: {
				color: '#F8F8F8',
				backgroundColor: '#F5826B',
				boxShadow: 'none',
				// borderRadius: '8px !important',
				'&:hover': {
					backgroundColor: '#64B6CF',
					color: '#F8F8F8',
					boxShadow: 'none',
				},
			},

			contained: {
				boxShadow: 'none',
			},

			textPrimary: {
				color: '#00AAE5',
			},

			sizeLarge: {
				height: '42px',
			},
			sizeSmall: {
				height: '32px',
			},
			label: {
				fontSize: '16px',
				fontStyle: 'normal',
				fontWeight: 800,
				textTransform: 'unset',
			},
		},
		MuiTab: {
			root: {
				textTransform: 'unset',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#E6E6E6',
			},
		},
		MuiPickersDay: {
			root: {
				fontWeight: 'normal',
			},
		},
		MuiChip: {
			root: {
				backgroundColor: '#C6E9FA',
			},
			deleteIcon: {
				color: '#424242',
			},
		},
		MuiCheckbox: {
			root: {
				color: '#00AAE5',
			},
			disabled: {
				color: '#7e7e80',
			},
			colorSecondary: {
				color: '#7e7e80',
			},
		},
	},
	spacing: 8,
	palette: {
		primary: {
			main: '#64B6CF',
			sub: '',
		},
		secondary: {
			main: '#64B6CF',
			sub: '#5BD5FF',
		},
		background: {
			default: '#ffffff',
			app: {
				dark: '#000000',
				light: '#FFFFFF',
			},
			element: {
				veryDark: '#2FAFEE',
				dark: '#42B6F0',
				medium: '#7BCCF4',
				light: '#EDF7FF',
				veryLight: '#C6E9FA',
				footerSideBar: '#00AAE5',
			},
		},
		content: {
			veryDark: '#333335',
			dark: '#7E7E80',
			medium: '#D1D1D1',
			light: '#E6E6E6',
			veryLight: '#F8F8F8',
		},
		status: {
			success: {
				dark: '#309102',
				medium: '#3BB700',
				light: '#A5FB7C',
			},
			warning: {
				dark: '#DE62A',
				medium: '#FF9B04',
				light: '#FF49F',
			},
			error: {
				dark: '#B9431F',
				medium: '#FF2727',
				light: '#FF9090',
			},
		},
		hyperlink: {
			dark: '#0C4AC2',
			medium: '#2C6EEE',
			light: '#9ABCFF',
		},

		error: {
			main: '#FF2727',
		},
		text: {
			primary: '#333335',
			secondary: '#7E7E80',
			tertiary: '#03606A',
			disabled: '#AEAEAE',
			blue: '#1C5CDB',
			hint: '#868686',
		},

		action: {
			active: '#868686',
			// hover: 'rgba(0, 0, 0, 0.04)',
			selected: 'rgba(0, 0, 0, 0.08)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
		},
		divider: 'rgba(0, 0, 0, 0.12)',
	},
	colors: {
		primary: {
			main: '#64B6CF',
			sub: '#91DDF4',
		},
		secondary: {
			main: '#F5826B',
			sub: '#E58B78',
		},
		background: {
			app: {
				dark: '#000000',
				light: '#FFFFFF',
			},
			element: {
				veryDark: '#2FAFEE',
				dark: '#C2DAF0',
				medium: '#DDEFFF',
				light: '#EDF7FF',
				veryLight: '#F6FBFF',
				footerSideBar: '#00AAE5',
			},
		},
		content: {
			veryDark: '#333335',
			dark: '#7E7E80',
			medium: '#C4C4C4',
			light: '#E6E6E6',
			veryLight: '#F8F8F8',
		},
		status: {
			success: {
				dark: '#309102',
				medium: '#3BB700',
				light: '#DFFFCF',
			},
			warning: {
				dark: '#DE662A',
				medium: '#FF9B04',
				light: '#FFE49F',
			},
			error: {
				dark: '#B9431F',
				medium: '#FF2727',
				light: '#FF9090',
			},
		},
		hyperlink: {
			dark: '#0C4AC2',
			medium: '#2C6EEE',
			light: '#D3E2FF',
		},
		other: {
			dark: '#D3E2FF',
			medium: '#CEB692',
			light: '#EEE3D2',
		},
	},
	shape: {
		borderRadius: 10,
	},
	borders: ['unset', 'solid 1px #e5e5e5', 'solid 1px rgba(130, 206, 207, 0.1)', 'solid 1px #82CECE'],
	typography: {
		fontFamily: ['Raleway'],
		h1: {
			fontSize: '32px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '44px',
			letterSpacing: '0px',
		},
		h2: {
			fontSize: '24px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '36px',
			letterSpacing: '0px',
		},
		h3: {
			fontSize: '20px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '28px',
			letterSpacing: '0px',
		},
		h4: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '24px',
			letterSpacing: '0px',
		},
		h5: {
			fontSize: '20px',
			lineHeight: '26px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		h6: {
			fontSize: '18px',
			lineHeight: '24px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title1: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '24px',
			letterSpacing: '0.1px',
		},
		title2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},

		subtitle1: {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},
		subtitle2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.2px',
		},
		subtitle3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 500,
			letterSpacing: '0px',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: 500,
			letterSpacing: '0px',
		},
		body3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0px',
		},
		price: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 800,
			letterSpacing: '0px',
			color: '#333335',
		},
		button: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: 700,
			letterSpacing: '0px',
		},
		caption: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: 500,
			letterSpacing: '0px',
		},
		ghostButton: {
			fontSize: '16px',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.2px',
		},
		footNote: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0px',
		},
		p1: {
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '22px',
			letterSpacing: '0.14px',
		},
		p2: {
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '18px',
			letterSpacing: '0.24px',
		},
		p3: {
			fontSize: '11px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			letterSpacing: '0.22px',
		},
	},
})
