import React from 'react'
import { careStore } from '~/companies/care/care.store'
import { Helmet } from 'react-helmet'
import './richWebChat.css'

export const ChatEngine = ({ ...props }) => {
	const { chatIntegrationSetting } = careStore

	return (
		<div {...props}>
			<div id="rwc"></div>
			<Helmet>
				<script src="https://chat.staffingengine.onereach.ai/lib/richWebChat.umd.min.js"></script>
				<script type="text/javascript">
					{`
            setTimeout(() => {
              var RWC = richWebChat.default;
              var app = new RWC(${chatIntegrationSetting?.configuration?.web});
            }, 1000);
          `}
				</script>
			</Helmet>
		</div>
	)
}
